import React, { useRef, useState, useEffect } from "react";
import BodyClassName from "react-body-classname";
import Layout from "../components/layout";
import Footer from "../components/footer";
import SEO from "../components/seo";
import { useIntl, Link } from "gatsby-plugin-intl";
import Slider from "react-slick";
import ImageViewer from "react-simple-image-viewer";
import _ from "lodash";
import { connect } from "react-redux";

const Hoteles = ({ pageContext, s }) => {
	const intl = useIntl();
	const { hotel } = pageContext;
	var sliderHotels = useRef(null);

	const [imageViewerImages, setImageViewerImages] = useState([]);
	const [imageViewerIndex, setImageViewerIndex] = useState(0);
	const [imageViewerShow, setImageViewerShow] = useState(false);

	const imageViewerOpen = (idx) => {
		setImageViewerIndex(idx);
		setImageViewerShow(true);
	};

	const imageViewerClose = () => {
		setImageViewerShow(false);
	};

	useEffect(() => {
		if (hotel) {
			const images = [];
			_.forEach(hotel.imagenes, (imagen) => {
				images.push(imagen.url);
			});
			setImageViewerImages(images);
		} else {
			setImageViewerImages([]);
			setImageViewerShow(false);
		}
	}, [hotel]);
	return (
		<>
			<BodyClassName className="hotels">
				<Layout>
					<SEO title={hotel.intl.title[intl.locale]} />
					<main className="page">
						{hotel.imagen_header_desktop && hotel.imagen_header_mobile && (
							<article className="bg-img">
								<h1 className="white-text saint-font">{hotel.intl.title[intl.locale]}</h1>
								<picture>
									<source srcset={hotel.imagen_header_desktop.url} media="(min-width: 768px)" />
									<img srcset={hotel.imagen_header_mobile.url} alt={hotel.intl.title[intl.locale]} />
								</picture>
								{/* <picture>
									<source srcset="/img/bg/faqs-desktop.jpg" media="(min-width: 768px)" />
									<img srcset="/img/bg/faqs.jpg" alt="" />
								</picture> */}
							</article>
						)}
						<article className="text box">
							<ul className="breadcrumbs white-bg">
								<li className="link">
									<Link to="/" className="ltblue-text-02">
										Home
									</Link>
								</li>
								<li className="active">
									<p className="lightgray-text-07">Hoteles</p>
								</li>
							</ul>
							<div className="wrapper-text">
								<p
									className="black-text-01"
									dangerouslySetInnerHTML={{ __html: hotel.intl.descripcion[intl.locale] }}
								/>
								<a className="ltblue-text-02" href={hotel.link} target="_blank" rel="noreferrer">
									{hotel.link}
								</a>
							</div>
							<div className="slider slider-hotels">
								{hotel.imagenes && hotel.imagenes.length > 0 && (
									<Slider
										dots={false}
										arrows={false}
										infinite={false}
										slidesToShow={1}
										slidesToScroll={1}
										slidesPerRow={1}
										ref={(c) => (sliderHotels = c)}
									>
										{hotel.imagenes.map((imagen, idx) => {
											return (
												<div className="slider-hotels-item" key={idx}>
													<button onClick={() => imageViewerOpen(idx)}>
														<img src={imagen.url} alt="" />
													</button>
												</div>
											);
										})}
									</Slider>
								)}
								{hotel.imagenes.length > 1 && (
									<div className="slider-arrows">
										<span className="arrow left" onClick={() => sliderHotels.slickPrev()}></span>
										<span className="arrow right" onClick={() => sliderHotels.slickNext()}></span>
									</div>
								)}
								{imageViewerShow && (
									<ImageViewer
										src={imageViewerImages}
										currentIndex={imageViewerIndex}
										onClose={imageViewerClose}
										backgroundStyle={{ zIndex: 9999, backgroundColor: "rgba(0, 0, 0, .9)" }}
									/>
								)}
							</div>
						</article>
						<Footer />
					</main>
				</Layout>
			</BodyClassName>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		s: state.app.boot.strings,
	};
};

export default connect(mapStateToProps)(Hoteles);
